import React, {MouseEventHandler, useEffect} from 'react';
import {Card, CardContent, createTheme, ThemeProvider, Typography} from "@mui/material";
import {mamboJumboFunction} from "./header.utils";

const theme = createTheme({
    palette: {
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#19857b',
        },
    },
});


function App() {

   useEffect(() => {
       mamboJumboFunction()
   }, [])



    return (
        <ThemeProvider theme={theme}>
            <h1 data-value="ARKA GDYNIA KURWA ŚWINIA">ARKA GDYNIA KURWA ŚWINIA</h1>
        </ThemeProvider>
    );
}

export default App;

